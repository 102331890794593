import { useRecoilCallback, useRecoilValue } from 'recoil';
import {
  BackdropLoader,
  IconAssocaaf,
  LBTAlert,
  LBTButton,
  LBTDivider,
  LBTLabel,
  LBTSpacer,
  ProgressIndicatorWidget,
  QuestionaryContext,
  Section,
} from '..';
import {
  QUESTIONARY_STEPS,
  QuestionFlow,
  continueFlowCallback,
  evalActionCallback,
  continueFlowState,
  useBreakpoint,
  updateVisibilityCallback,
  QUESTION_TYPES_ENUM,
  DestinationTypeEnum,
  Question as QuestionType,
  ContinueFlowQuestion,
  slugify,
  useTrackAnalytics,
  ActionTypeEnum,
  OptionType,
  EntityActionTypeEnum,
  currentUserState,
  APP_ROUTES,
  useLoading,
  resetContinueFlowStateCallback,
  getUserProfileCallback,
  currentCompanyConfigState,
  ListValue,
  Value,
  Answer,
  CheckboxValue,
} from '@laborability/commons';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { COLORS } from '../../utils';
import Question from './Question';
import Action from './Action';
import { Box, CardMedia, Link } from '@mui/material';
import BoldSpan from './BoldSpan';
import { useNavigate } from 'react-router-dom';

interface Props {
  flow: QuestionFlow;
  isDemo?: boolean;
  preview?: boolean;
}

export function findAnswer(
  values: Answer[],
  qId: number,
  eId: Number,
): Answer | undefined {
  return values.find(
    value => value.question_id === qId && value.entity_id === eId,
  );
}

export default function Page({ flow, isDemo = false, preview = false }: Props) {
  const isLoading = useLoading();
  const { isDesktop } = useBreakpoint();
  const { pageId } = useContext(QuestionaryContext);
  const pageData = useRecoilValue(continueFlowState);
  const [questionsLoader, setQuestionsLoader] = useState<number>(0);
  const [lastPageNumber, setLastPageNumber] = useState<number>(0);
  const { assocaf } = useRecoilValue(currentCompanyConfigState);
  const [trackAnalyticsProps, setTrackAnalyticsProps] = useState<
    {
      key: string;
      value: string;
    }[]
  >([]);
  const pageNumber = pageData.position ?? 1;

  const getTrackAnalyticsProps = () => {
    if (flow.name && pageNumber && pageNumber !== lastPageNumber) {
      setLastPageNumber(pageNumber);
      return [
        { key: 'event', value: 'page_view' },
        {
          key: 'page_title',
          value: `Questionario - ${flow.name} - Step ${pageNumber}`,
        },
        {
          key: 'page_location',
          value: `/onboarding/${slugify(flow.name)}/${pageNumber}`,
        },
      ];
    }
    return [];
  };

  useTrackAnalytics(!isLoading && !preview ? trackAnalyticsProps : []);

  useEffect(() => {
    setTrackAnalyticsProps(getTrackAnalyticsProps());
  }, [flow.name, isLoading, pageNumber]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageId]);

  return (
    <>
      {!!questionsLoader && <BackdropLoader />}
      <Section
        style={{
          display: 'flex',
          flexDirection: isDesktop ? 'row' : 'column',
          justifyContent: 'space-between',
          minHeight: 'calc(100vh - 200px)',
          height: '100%',
        }}
      >
        <Stepper flow={flow} pageNumber={pageNumber} preview={preview} />
        {pageId ? (
          <QuestionSection
            flowId={flow.id!}
            flowName={flow.name!}
            setQuestionsLoader={setQuestionsLoader}
            isDemo={isDemo}
          />
        ) : (
          <div />
        )}
        <div style={{ display: 'flex', flex: 1, paddingRight: '64px' }} />
      </Section>
      {pageId && <MediaSection />}
      {pageId && assocaf && <AssocaafSection />}
    </>
  );
}

interface QuestionSectionProps {
  flowId: number;
  flowName: string;
  setQuestionsLoader: React.Dispatch<React.SetStateAction<number>>;
  isDemo: boolean;
}

function QuestionSection({
  flowId,
  flowName,
  setQuestionsLoader,
  isDemo,
}: QuestionSectionProps) {
  const { isDesktop } = useBreakpoint();
  const { pageId, step, setPageId, setStep, setIsStepCompleted } =
    useContext(QuestionaryContext);
  const pageData = useRecoilValue(continueFlowState);
  const [values, setValues] = useState<Answer[]>([]);
  const [errors, setErrors] = useState<number[]>([]);
  const [reload, setReload] = useState<boolean>(false);
  const changeVisibilityTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const getPage = useRecoilCallback(continueFlowCallback, []);
  const resetPageState = useRecoilCallback(resetContinueFlowStateCallback, []);
  const evalAction = useRecoilCallback(evalActionCallback, []);
  const updateVisibility = useRecoilCallback(updateVisibilityCallback, []);
  const getUserMe = useRecoilCallback(getUserProfileCallback, []);
  const actions = pageData.actions;
  const thereIsSkip = actions.some(
    action => action.action_type === ActionTypeEnum.skip,
  );
  const navigate = useNavigate();
  const question = pageData.questions;
  const autoAction = actions?.find(item => item.action_type === 'auto');
  const requiredQuestions =
    question.filter(item => item.question.required && item.question.visible) ??
    [];
  const hasQuestionRules = pageData.has_question_rules;
  const helpTip = pageData?.tips?.find(item => item.type === 'help');
  const entertainmentTip = pageData?.tips?.find(
    item => item.type === 'entertainment',
  );
  const isEmpty = question.every(item => !item.answer_value);

  const trackCompltedQuestionary = () => {
    const dataLayer = {
      event: 'questionario',
      questionary: slugify(flowName),
    };

    console.log('@@@tagManager', dataLayer);
    window.dataLayer.push(dataLayer);
  };

  const isListValue = (value: Value | undefined): value is ListValue => {
    return (
      typeof value === 'object' &&
      value !== null &&
      'type' in value &&
      Array.isArray(value.value) &&
      value.value.every(item => typeof item === 'number')
    );
  };

  const isCheckBoxValue = (
    value: Value | undefined,
    optionId: string,
  ): value is CheckboxValue => {
    return (
      typeof value === 'object' &&
      value !== null &&
      'type' in value &&
      (value.type === OptionType.domain ||
        (optionId === 'user' &&
          Array.isArray(value.value) &&
          value.value.every(item => typeof item === 'number')) ||
        (value.type === OptionType.entity &&
          typeof value.value === 'object' &&
          Object.values(value.value).every(item => typeof item === 'string')))
    );
  };

  const isSkipVisible = () => {
    if (errors.length) return false;

    const allQuestions = question.filter(item => item.question.visible);
    if (
      allQuestions.some(
        item =>
          findAnswer(values, item.question_id, item.entity_id)?.answer_value,
      )
    )
      return false;

    for (let i = 0; i < allQuestions.length; i++) {
      const question = allQuestions[i];
      const answer = findAnswer(
        values,
        question.question_id,
        question.entity_id,
      )?.answer_value;

      if (question.question.question_type === QUESTION_TYPES_ENUM.switch)
        continue;
      if (!answer || (isListValue(answer) && !answer.value.length)) {
        if (question.question.question_type !== QUESTION_TYPES_ENUM.checkbox) {
          return true;
        }
      }
    }
    return false;
  };

  const isButtonDisabled = () => {
    if (errors.length) return true;
    const requiredRadioAnswered = requiredQuestions.some(
      item =>
        item.question.question_type === QUESTION_TYPES_ENUM.radio &&
        findAnswer(values, item.question_id, item.entity_id)?.answer_value,
    );
    const requiredCheckBoxAnswered = requiredQuestions.some(item => {
      const answer = findAnswer(
        values,
        item.question_id,
        item.entity_id,
      )?.answer_value;
      const optionId = item.question.question_meta?.options as string;
      if (
        item.question.question_type === QUESTION_TYPES_ENUM.checkbox &&
        isCheckBoxValue(answer, optionId)
      ) {
        if (answer.type === OptionType.domain || optionId === 'user') {
          return answer.value.length;
        }
        if (answer.type === OptionType.entity) {
          return Object.keys(answer.value).some(
            item => answer.value[item] === 'true',
          );
        }
      }
    });

    for (let i = 0; i < requiredQuestions.length; i++) {
      const question = requiredQuestions[i];
      const answer = findAnswer(
        values,
        question.question_id,
        question.entity_id,
      )?.answer_value;
      if (question.question.question_type === QUESTION_TYPES_ENUM.switch)
        continue;
      if (!answer || (isListValue(answer) && !answer.value.length)) {
        if (question.question.question_type === QUESTION_TYPES_ENUM.radio) {
          if (!requiredRadioAnswered) {
            return true;
          }
        } else if (
          question.question.question_type === QUESTION_TYPES_ENUM.checkbox
        ) {
          if (!requiredCheckBoxAnswered) {
            return true;
          }
        } else {
          return true;
        }
      }
    }
    return false;
  };

  const isValidCheckbox = (
    value: Answer,
    currentQuestion: ContinueFlowQuestion | undefined,
  ) => {
    return !(
      value.question_type === QUESTION_TYPES_ENUM.checkbox &&
      isCheckBoxValue(
        value.answer_value,
        currentQuestion?.question.question_meta?.options as string,
      ) &&
      Object.keys(value.answer_value.value).length === 0
    );
  };

  const evalButtonAction = async (
    action_id: number,
    values: Answer[],
    hasLoop?: boolean,
  ) => {
    if (isDemo) return;
    const filteredQuestion = values.filter(value => {
      const currentQuestion = question.find(
        item =>
          item.question_id === value.question_id &&
          item.entity_id === value.entity_id,
      );
      return (
        isValidCheckbox(value, currentQuestion) &&
        (value.question_type === QUESTION_TYPES_ENUM.switch ||
          (value.question_type === QUESTION_TYPES_ENUM.checkbox &&
            (currentQuestion?.question.question_meta?.options as string) ===
              'user') ||
          value.answer_value !== null) &&
        currentQuestion?.question.visible
      );
    });
    const answer: Answer[] = filteredQuestion.map(value => {
      if (
        value.answer_value === '' &&
        value.question_type === QUESTION_TYPES_ENUM.radio
      ) {
        return {
          ...value,
          answer_value: { type: OptionType.domain, value: [] },
        };
      }
      if (
        !value.answer_value &&
        value.question_type === QUESTION_TYPES_ENUM.switch
      ) {
        return {
          ...value,
          answer_value: false,
        };
      }
      if (value.question_type === QUESTION_TYPES_ENUM.checkbox) {
        const optionId = question.find(
          item =>
            item.question_id === value.question_id &&
            item.entity_id === value.entity_id,
        )?.question.question_meta?.options as string;
        if (optionId === 'user') {
          if (isCheckBoxValue(value.answer_value, optionId)) {
            return {
              ...value,
              answer_value: true,
            };
          } else {
            return {
              ...value,
              answer_value: false,
            };
          }
        }
      }
      return value;
    });

    const res = await evalAction({
      flow_id: flowId,
      page_id: pageId,
      action_id,
      questions: answer,
    });
    if (res?.data?.type === DestinationTypeEnum.menu) {
      trackCompltedQuestionary();
      setPageId(0);
      setStep(step === 3 ? 3 : 0);
      //setStep(0);
      if (step !== 3) getUserMe();
      resetPageState();
      setValues([]);
      setIsStepCompleted(true);
    } else if (res?.data?.type === DestinationTypeEnum.next_step) {
      trackCompltedQuestionary();
      setPageId(0);
      resetPageState();
      setStep(res.data?.destination_step ?? 0);
      setValues([]);
    } else if (res?.data?.type === DestinationTypeEnum.home_page) {
      trackCompltedQuestionary();
      setPageId(0);
      resetPageState();
      setStep(0);
      setValues([]);
    } else if (res?.data?.target_page_id) {
      setPageId(res.data.target_page_id);
      setValues(
        values.map(value => {
          return { ...value, answer_value: '' };
        }),
      );
      if (hasLoop || !isEmpty) {
        setReload(!reload);
      }
    }
  };

  const changeQuestionsVisibility = async (values: Answer[]) => {
    setQuestionsLoader?.((l: number) => l + 1);
    setValues(values);
    const res = await updateVisibility({
      flow_id: flowId,
      page_id: pageId,
      questions: values.filter(
        value =>
          value.answer_value !== null &&
          (!(value.answer_value as ListValue)?.type ||
            !!(value.answer_value as ListValue)?.value?.length),
      ),
    });

    // elimina le risposte alle domande non più visibili
    const visibleQuestions = res?.data?.questions?.filter(
      (item: Answer & { question: QuestionType }) => item.question.visible,
    );
    if (visibleQuestions)
      setValues(
        visibleQuestions.map((item: Answer & { question: QuestionType }) => ({
          question_id: item.question.id!,
          entity_id: item.entity_id,
          answer_value: item.answer_value,
          question_type: item.question.question_type,
        })),
      );
    setQuestionsLoader?.((l: number) => l - 1);
  };

  const getNextPage = async () => {
    const res = await getPage({ flow_id: flowId, page_id: pageId });
    if (res?.data) {
      setValues(
        res.data?.questions
          ?.filter((item: ContinueFlowQuestion) => item.question.visible)
          ?.map((item: ContinueFlowQuestion) => ({
            question_id: item.question_id,
            entity_id: item.entity_id,
            answer_value: item.answer_value,
            question_type: item.question.question_type,
          })) ?? [],
      );
    }
  };

  useEffect(() => {
    if (flowId && pageId) getNextPage();
  }, [flowId, pageId, reload]);

  return (
    <div
      style={{
        width: '100%',
        maxWidth: isDesktop ? '680px' : '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'flex-start',
      }}
    >
      <LBTSpacer spacing={4} isFixed />
      <LBTLabel variant="delaDisplay">{pageData.name}</LBTLabel>
      {pageData?.description && (
        <>
          <LBTSpacer spacing={2} />
          <LBTLabel variant="spGroteskSubtitle">
            {pageData.description}
          </LBTLabel>
        </>
      )}
      {pageData?.hint && (
        <>
          <LBTSpacer spacing={2} />
          <LBTLabel variant="bodyText">{pageData.hint}</LBTLabel>
        </>
      )}
      <LBTSpacer spacing={4} />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: '504px',
        }}
      >
        {question.map(item => {
          if (!item.question.visible) return null;
          return (
            <Fragment key={`${item.question_id}_${item.entity_id}`}>
              <Question
                question={item.question}
                questions={question}
                value={
                  findAnswer(values, item.question_id, item.entity_id)
                    ?.answer_value
                }
                values={values}
                handleChange={(value: Value) => {
                  // update current values
                  let isUpdated = false;
                  let resetRadio: Answer[] = values;
                  if (
                    item.question.question_type === QUESTION_TYPES_ENUM.radio
                  ) {
                    resetRadio = values.filter(
                      v => v.question_type !== QUESTION_TYPES_ENUM.radio,
                    );
                  }

                  let newValues = resetRadio.reduce(
                    (prev: Answer[], current) => {
                      if (
                        current.question_id === item.question_id &&
                        current.entity_id === item.entity_id
                      ) {
                        isUpdated = true;
                        return [
                          ...prev,
                          {
                            ...current,
                            answer_value: value,
                          },
                        ];
                      }
                      return [...prev, current];
                    },
                    [],
                  );
                  if (!isUpdated)
                    newValues = [
                      ...newValues,
                      {
                        question_id: item.question.id!,
                        entity_id: item.entity_id,
                        answer_value: value,
                        question_type: item.question.question_type,
                      },
                    ];

                  if (
                    item.question.question_type === QUESTION_TYPES_ENUM.radio
                  ) {
                    question.map(singleQuestion => {
                      if (item.question.id !== singleQuestion.question_id) {
                        newValues = [
                          ...newValues,
                          {
                            question_id: singleQuestion.question.id!,
                            entity_id: singleQuestion.entity_id,
                            answer_value: '',
                            question_type:
                              singleQuestion.question.question_type,
                          },
                        ];
                      }
                    });
                  }
                  // eval auto action
                  if (autoAction) {
                    for (let i = 0; i < requiredQuestions.length; i++) {
                      let answer = findAnswer(
                        newValues,
                        requiredQuestions[i].question_id,
                        requiredQuestions[i].entity_id,
                      )?.answer_value;
                      if (
                        answer === undefined ||
                        answer === '' ||
                        answer === null
                      )
                        return;
                    }

                    evalButtonAction(autoAction.id!, newValues);
                  }
                  // change question visibility
                  else if (hasQuestionRules) {
                    if (changeVisibilityTimeoutRef.current)
                      clearTimeout(changeVisibilityTimeoutRef.current);
                    if (
                      item.question.question_type ===
                        QUESTION_TYPES_ENUM.text ||
                      item.question.question_type === QUESTION_TYPES_ENUM.date
                    )
                      changeVisibilityTimeoutRef.current = setTimeout(
                        () => changeQuestionsVisibility(newValues),
                        1000,
                      );
                    else changeQuestionsVisibility(newValues);
                  } else setValues(newValues);
                }}
                setLoader={setQuestionsLoader}
                setError={setErrors}
              />
              {item.question.question_type !== QUESTION_TYPES_ENUM.checkbox &&
                !(
                  item.question.question_type === QUESTION_TYPES_ENUM.text &&
                  item.question.multi_entity
                ) && <LBTSpacer spacing={4} isFixed />}
            </Fragment>
          );
        })}

        {actions?.map(action => {
          const isSkipAction = action.action_type === ActionTypeEnum.skip;
          const hasHomepageDestination =
            action.destination === DestinationTypeEnum.home_page;
          const buttonStyle = action.action_meta?.button_style;
          const buttonAction = action.action_meta?.action;
          const isLoop = buttonAction === EntityActionTypeEnum.loop;
          const variant =
            buttonStyle === 'outlined' || buttonStyle === 'contained'
              ? buttonStyle
              : 'contained';
          const shouldRenderSkip =
            thereIsSkip && isSkipVisible() && isSkipAction;
          const shouldRenderAction = thereIsSkip
            ? !isSkipVisible() && !isSkipAction
            : true;

          const questionIsBoolean = question.some(
            item => item.question.question_type === QUESTION_TYPES_ENUM.boolean,
          );

          if (shouldRenderSkip) {
            return (
              <Fragment key={action.id}>
                <LBTButton
                  variant="contained"
                  onClick={() => evalButtonAction(action.id!, values, isLoop)}
                  fullWidth
                  size="large"
                >
                  {action.name}
                </LBTButton>
                <LBTSpacer spacing={4} />
              </Fragment>
            );
          }

          if (shouldRenderAction) {
            return (
              <Fragment key={action.id}>
                <Action
                  variant={variant}
                  action={action}
                  evalAction={() =>
                    hasHomepageDestination
                      ? navigate(`/${APP_ROUTES.HOME}`)
                      : evalButtonAction(action.id!, values, isLoop)
                  }
                  isButtonDisabled={isButtonDisabled()}
                />
                {!questionIsBoolean && <LBTSpacer spacing={4} />}
              </Fragment>
            );
          }
        })}

        {helpTip && (
          <Box>
            <LBTAlert
              title={helpTip.title}
              message={helpTip.message}
              variant="standard"
              color="help"
            />
            <LBTSpacer spacing={2} />
          </Box>
        )}

        {entertainmentTip && (
          <Box>
            <LBTAlert
              title={entertainmentTip.title}
              message={entertainmentTip.message}
              variant="standard"
              color="entertainment"
            />
            <LBTSpacer spacing={2} />
          </Box>
        )}
      </Box>
    </div>
  );
}

function MediaSection() {
  const pageData = useRecoilValue(continueFlowState);

  if (!pageData?.media_title || !pageData?.media_link) return null;

  return (
    <Section
      backgroundColor={COLORS.getInstance().PRIMARY_IPERLIGHT}
      style={{ display: 'flex', alignItems: 'center' }}
    >
      <LBTSpacer spacing={4} />
      <LBTLabel variant="delaDisplay">{pageData.media_title}</LBTLabel>
      {pageData?.media_subtitle && (
        <>
          <LBTSpacer spacing={2} />
          <LBTLabel variant="spGroteskSubtitle">
            {pageData?.media_subtitle}
          </LBTLabel>
        </>
      )}
      {pageData?.media_description && (
        <>
          <LBTSpacer spacing={2} />
          <LBTLabel variant="bodyText">{pageData.media_description}</LBTLabel>
        </>
      )}
      <LBTSpacer spacing={4} />
      <Box width="358px" height="644px">
        <CardMedia
          sx={{ border: 'none', height: '100%' }}
          component="iframe"
          image={pageData.media_link}
          title="LBT-Video"
        />
      </Box>
      <LBTSpacer spacing={2} />
    </Section>
  );
}

function AssocaafSection() {
  const pageData = useRecoilValue(continueFlowState);

  if (!pageData?.flag_assocaf) return null;

  return (
    <Section style={{ display: 'flex', alignItems: 'center' }}>
      <LBTSpacer spacing={4} />
      <LBTLabel variant="delaDisplay" component="h3">
        Ti serve aiuto per fare domanda?
      </LBTLabel>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="spGroteskSubtitle" component="h4">
        Assocaaf richiede per te l’Indicatore della Situazione Economica
        Equivalente (ISEE). Scrivi a dritto@assocaaf.it per farti aiutare.
      </LBTLabel>
      <LBTSpacer spacing={2} />
      <IconAssocaaf />
      <LBTSpacer spacing={2} />
      <LBTLabel variant="bodyText">
        Assocaaf Spa è un nostro partner, ed è il centro di assistenza fiscale
        costituito dalle associazioni territoriali e di categoria di
        Confindustria e da oltre mille imprese nazionali e multinazionali
      </LBTLabel>
      <LBTSpacer spacing={4} />
      <div style={{ maxWidth: '680px', width: '100%' }}>
        <LBTLabel variant="smallCapsBold" component="h5" textAlign="left">
          Costi
        </LBTLabel>
        <LBTSpacer spacing={2} />
        <ul
          style={{
            margin: 0,
            padding: 0,
            borderTop: `2px solid ${COLORS.getInstance().BW_GREYS_JET_BLACK}26`,
          }}
        >
          <li
            style={{
              justifyContent: 'space-between',
              padding: '16px 4px',
              display: 'flex',
              borderBottom: `1px solid ${COLORS.getInstance().BW_GREYS_JET_BLACK}26`,
            }}
          >
            <LBTLabel variant="listTitle" textAlign="left">
              Prima DSU
            </LBTLabel>
            <LBTLabel variant="buttonLarge" textAlign="right" minWidth="60px">
              0€
            </LBTLabel>
          </li>
          <li
            style={{
              justifyContent: 'space-between',
              padding: '16px 4px',
              display: 'flex',
              borderBottom: `1px solid ${COLORS.getInstance().BW_GREYS_JET_BLACK}26`,
            }}
          >
            <LBTLabel variant="listTitle" textAlign="left">
              Presentazioni successive con variazione dei componenti del nucleo
              familiare
            </LBTLabel>
            <LBTLabel variant="buttonLarge" textAlign="right" minWidth="60px">
              0€
            </LBTLabel>
          </li>
          <li
            style={{
              justifyContent: 'space-between',
              padding: '16px 4px',
              display: 'flex',
            }}
          >
            <LBTLabel variant="listTitle" textAlign="left">
              Richieste ed elaborazioni successive, per lo stesso nucleo e anno
            </LBTLabel>
            <LBTLabel variant="buttonLarge" textAlign="right" minWidth="60px">
              20€
            </LBTLabel>
          </li>
        </ul>
      </div>
      <LBTSpacer spacing={2} />
      <LBTDivider />
      <LBTSpacer spacing={4} />
      <LBTLabel variant="bodyText">
        Per farti aiutare, scrivi a{' '}
        <Link
          href="mailto:dritto@assocaaf.it"
          color={COLORS.getInstance().BW_GREYS_JET_BLACK}
        >
          <BoldSpan>dritto@assocaaf.it</BoldSpan>
        </Link>
      </LBTLabel>
      <LBTSpacer spacing={4} />
    </Section>
  );
}

interface StepperProps {
  flow: QuestionFlow;
  pageNumber: number;
  preview: boolean;
}

function Stepper({ flow, pageNumber, preview }: StepperProps) {
  const { isDesktop } = useBreakpoint();
  const currentUser = useRecoilValue(currentUserState);
  const position = currentUser.questionary_status?.some(
    questionary => questionary.step === flow.step,
  );
  return (
    <div
      style={
        isDesktop
          ? {
              position: 'sticky',
              top: '100px',
              alignItems: 'flex-end',
              alignSelf: 'flex-start',
              height: '680px',
              maxHeight: '680px',
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              paddingRight: '64px',
            }
          : {
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              alignItems: 'center',
            }
      }
    >
      <LBTSpacer spacing={2} />
      <ProgressIndicatorWidget
        label={flow?.name as string}
        step={
          position || currentUser.questionary_status?.length === 0 || preview
            ? flow.step!
            : currentUser.questionary_status!.length + 1
        }
        stepsNumber={QUESTIONARY_STEPS}
        item={{
          currentValue: pageNumber,
          maxValue: flow.number_of_pages ?? 1,
        }}
        orientation={isDesktop ? 'vertical' : 'horizontal'}
      />
      {isDesktop && <LBTSpacer spacing={2} />}
    </div>
  );
}
