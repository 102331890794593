import {
  LBTLabel,
  COLORS,
  LBTSpacer,
  Section,
  LBTButtonIllustrative,
  LBTAlert,
  LBTButton,
  BackdropLoader,
} from '@laborability/components';
import {
  ActionTypeEnum,
  Answer,
  continueFlowCallback,
  ContinueFlowQuestion,
  ContinueFlowResponse,
  currentUserState,
  evalActionCallback,
  getAllQuestionPageCallback,
  getAnswersCallback,
  isStepThreeCompleted,
  OptionType,
  QUESTION_TYPES_ENUM,
  QuestionPage,
  useBreakpoint,
  useLoading,
  UserAnswers,
  UserAnswersFlow,
  UserAnswersPage,
  userAnswersState,
  useTrackAnalytics,
} from '@laborability/commons';
import { Grid } from '@mui/material';
import { IconCasa } from '../../components/icons/IconCasa';
import { IconCultura } from '../../components/icons/IconCultura';
import { IconGenitorialita } from '../../components/icons/IconGenitorialita';
import { IconIstruzione } from '../../components/icons/IconIstruzione';
import { IconSalute } from '../../components/icons/IconSalute';
import { IconTrasporti } from '../../components/icons/IconTrasporti';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';

interface Props {
  setStep: (i: number) => void;
  setPageId: (i: number) => void;
}

export default function MyConcessions({ setStep, setPageId }: Props) {
  const { isDesktop } = useBreakpoint();
  const currentUser = useRecoilValue(currentUserState);
  const getPage = useRecoilCallback(continueFlowCallback, []);
  const getAnswers = useRecoilCallback(getAnswersCallback, []);
  const evalAction = useRecoilCallback(evalActionCallback, []);
  const answers = useRecoilValue(userAnswersState);
  const isLoading = useLoading();
  const [loader, setLoader] = useState<number>(0);
  const [trackAnalyticsProps, setTrackAnalyticsProps] = useState<
    {
      key: string;
      value: string;
    }[]
  >([]);
  const [stepThreeAnswers, setStepThreeAnswers] = useState<UserAnswersPage[]>(
    [],
  );
  const [stepThreeQuestions, setStepThreeQuestions] = useState<QuestionPage[]>(
    [],
  );
  const [stepThreeCompleted, setStepThreeCompleted] = useState(false);
  const getAllPage = useRecoilCallback(getAllQuestionPageCallback, []);

  const isStepCompleted = (step: number) => {
    return currentUser.questionary_status?.some(
      questionary => questionary.completed && questionary.step === step,
    );
  };

  const resumeQuestionary = async (step: number) => {
    const status = currentUser.questionary_status?.find(
      item => item.step === step,
    );
    if (step === 3) {
      if (
        !currentUser.questionary_status?.find(flow => flow.step === 3)
          ?.total_responses
      ) {
        setStep(step);
        setPageId(0);
        return;
      }
      const skipPages = stepThreeQuestions.filter(page =>
        page.actions?.some(
          action => action.action_type === ActionTypeEnum.skip,
        ),
      );
      const unrespondedAnswers = skipPages
        .filter(
          page =>
            !stepThreeAnswers.map(answer => answer.page_id).includes(page.id!),
        )
        .sort((a, b) => a.page_order! - b.page_order!);
      if (unrespondedAnswers.length) {
        setStep(step);
        setPageId(unrespondedAnswers[0].id!);
        return;
      }
    }

    if (!status?.total_responses) {
      setStep(step);
      setPageId(0);
      return;
    }

    const selectedFlow = answers.flows.find(
      (flow: UserAnswersFlow) => flow.flow_id === status.flow_id,
    );

    const sortedPages = [...(selectedFlow?.pages || [])].sort(
      (a, b) => b.page_order - a.page_order,
    );

    const res = await getPage({
      flow_id: status.flow_id,
      page_id: sortedPages?.length ? sortedPages[0].page_id : status.page_id,
    });

    if (res?.data) {
      const pageData = res.data as ContinueFlowResponse;
      const actionId = pageData.actions.filter(
        action =>
          action.default_target_page_id &&
          action.page_id !== action.default_target_page_id,
      )[0].id;
      const values: Answer[] =
        pageData?.questions
          ?.filter((item: ContinueFlowQuestion) => item.question.visible)
          ?.map((item: ContinueFlowQuestion) => ({
            question_id: item.question_id,
            entity_id: item.entity_id,
            answer_value: item.answer_value,
            question_type: item.question.question_type,
          })) ?? [];
      const filteredQuestion = values.filter(
        value =>
          (value.question_type === QUESTION_TYPES_ENUM.switch ||
            value.answer_value !== null) &&
          pageData.questions.find(
            item =>
              item.question_id === value.question_id &&
              item.entity_id === value.entity_id,
          )?.question.visible,
      );
      const answer: Answer[] = filteredQuestion.map(value => {
        if (
          value.answer_value === '' &&
          value.question_type === QUESTION_TYPES_ENUM.radio
        ) {
          return {
            ...value,
            answer_value: { type: OptionType.domain, value: [] },
          };
        }
        if (
          !value.answer_value &&
          value.question_type === QUESTION_TYPES_ENUM.switch
        ) {
          return {
            ...value,
            answer_value: false,
          };
        }
        return value;
      });
      if (sortedPages?.length) {
        const evalActionRes = await evalAction({
          flow_id: status.flow_id,
          page_id: sortedPages[0].page_id,
          action_id: actionId!,
          questions: answer,
        });

        if (evalActionRes?.data?.target_page_id) {
          setStep(step);
          setPageId(evalActionRes.data.target_page_id ?? 0);
          return;
        }
      }
    }
    setStep(step);
    setPageId(status.page_id);
  };

  const getPages = async (id: number) => {
    const res = await getAllPage({ id: id });
    const questionPage: QuestionPage[] = res?.data.items;
    const resolvedQuestionPages = await Promise.all(questionPage);
    const pages = resolvedQuestionPages.flat();
    return pages as QuestionPage[];
  };

  useTrackAnalytics(!isLoading && !loader ? trackAnalyticsProps : []);

  useEffect(() => {
    if (stepThreeQuestions.length && stepThreeAnswers.length) {
      setStepThreeCompleted(
        isStepThreeCompleted(stepThreeAnswers, stepThreeQuestions),
      );
    }
  }, [stepThreeQuestions, stepThreeAnswers]);

  useEffect(() => {
    (async () => {
      setLoader?.((l: number) => l + 1);
      const fetchedAnswers = await getAnswers();
      const stepThreeFlow = (fetchedAnswers?.data as UserAnswers).flows.find(
        flow => flow.step === 3,
      );
      if (stepThreeFlow) {
        setStepThreeQuestions(await getPages(stepThreeFlow.flow_id));
        setStepThreeAnswers(stepThreeFlow.pages);
      }
      setLoader?.((l: number) => l - 1);
    })();
    setTrackAnalyticsProps([
      { key: 'event', value: 'page_view' },
      {
        key: 'page_title',
        value: 'Questionario - Overview misure sbloccate',
      },
      { key: 'page_location', value: '/onboarding/overview' },
    ]);
  }, []);

  if (!!loader) return <BackdropLoader />;

  return (
    <Section backgroundColor={COLORS.getInstance().WHITE}>
      <LBTSpacer spacing={4} />
      <LBTLabel variant="delaDisplay" component="h3">
        Le tue agevolazioni
      </LBTLabel>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="spGroteskSubtitle">
        Sblocca le agevolazioni che ti spettano rispondendo a qualche domanda
        per ogni categoria. Abbiamo già fatto una grossa scrematura grazie alle
        risposte che ci hai dato fin qui
      </LBTLabel>
      <LBTSpacer spacing={4} />
      <LBTAlert
        sx={{
          maxWidth: '358px',
          display: stepThreeCompleted ? 'none' : 'flex',
        }}
        onClose={() => {}}
        color="error"
        variant="standard"
        title="Bonus non verificabili"
        message="Sono le agevolazioni legate alle super domande. Se ne hai saltate, ti consigliamo di rispondere anche solo con una stima"
        datatestid={''}
        customCloseComponent={
          <LBTButton
            color="error"
            size="small"
            variant="invisible"
            onClick={() => resumeQuestionary(3)}
            datatestid="onboarding_button_risolvi_sblocca_agevolazioni"
          >
            RISOLVI
          </LBTButton>
        }
      />
      <LBTSpacer spacing={4} />
      <Grid
        container
        spacing={isDesktop ? '24px' : '16px'}
        style={{ maxWidth: isDesktop ? '1032px' : '390px', width: '100%' }}
      >
        <Grid item mobile={6} desktop={4}>
          <LBTButtonIllustrative
            datatestid={
              isStepCompleted(4)
                ? `onboarding_button_cat_salute_lolocked`
                : `onboarding_button_cat_salute_unlocked`
            }
            onClick={() => resumeQuestionary(4)}
            icon={<IconSalute locked={isStepCompleted(4)} />}
            label="Salute"
            locked={isStepCompleted(4)}
            iconBgColor={
              isStepCompleted(4)
                ? COLORS.getInstance().MINT_TONIC_MAIN
                : COLORS.getInstance().PRIMARY_MAIN
            }
          />
        </Grid>
        <Grid item mobile={6} desktop={4}>
          <LBTButtonIllustrative
            datatestid={
              isStepCompleted(5)
                ? `home_button_cat_genitorialita_locked`
                : `home_button_cat_genitorialita_unlocked`
            }
            onClick={() => resumeQuestionary(5)}
            icon={<IconGenitorialita locked={isStepCompleted(5)} />}
            label="Genitorialità"
            locked={isStepCompleted(5)}
            iconBgColor={
              isStepCompleted(5)
                ? COLORS.getInstance().MINT_TONIC_MAIN
                : COLORS.getInstance().PRIMARY_MAIN
            }
          />
        </Grid>
        <Grid item mobile={6} desktop={4}>
          <LBTButtonIllustrative
            datatestid={
              isStepCompleted(6)
                ? `home_button_cat_casa_locked`
                : `home_button_cat_casa_unlocked`
            }
            onClick={() => resumeQuestionary(6)}
            icon={<IconCasa locked={isStepCompleted(6)} />}
            label="Casa"
            locked={isStepCompleted(6)}
            iconBgColor={
              isStepCompleted(6)
                ? COLORS.getInstance().MINT_TONIC_MAIN
                : COLORS.getInstance().PRIMARY_MAIN
            }
          />
        </Grid>
        <Grid item mobile={6} desktop={4}>
          <LBTButtonIllustrative
            datatestid={
              isStepCompleted(7)
                ? `home_button_cat_trasporti_locked`
                : `home_button_cat_trasporti_unlocked`
            }
            onClick={() => resumeQuestionary(7)}
            icon={<IconTrasporti locked={isStepCompleted(7)} />}
            label="Trasporti"
            locked={isStepCompleted(7)}
            iconBgColor={
              isStepCompleted(7)
                ? COLORS.getInstance().MINT_TONIC_MAIN
                : COLORS.getInstance().PRIMARY_MAIN
            }
          />
        </Grid>
        <Grid item mobile={6} desktop={4}>
          <LBTButtonIllustrative
            datatestid={
              isStepCompleted(8)
                ? `home_button_cat_cultura_locked`
                : `home_button_cat_cultura_unlocked`
            }
            onClick={() => resumeQuestionary(8)}
            icon={<IconCultura locked={isStepCompleted(8)} />}
            label="Cultura"
            locked={isStepCompleted(8)}
            iconBgColor={
              isStepCompleted(8)
                ? COLORS.getInstance().MINT_TONIC_MAIN
                : COLORS.getInstance().PRIMARY_MAIN
            }
          />
        </Grid>
        <Grid item mobile={6} desktop={4}>
          <LBTButtonIllustrative
            datatestid={
              isStepCompleted(9)
                ? `home_button_cat_istruzione_locked`
                : `home_button_cat_istruzione_unlocked`
            }
            onClick={() => resumeQuestionary(9)}
            icon={<IconIstruzione locked={isStepCompleted(9)} />}
            label="Istruzione"
            locked={isStepCompleted(9)}
            iconBgColor={
              isStepCompleted(9)
                ? COLORS.getInstance().MINT_TONIC_MAIN
                : COLORS.getInstance().PRIMARY_MAIN
            }
          />
        </Grid>
      </Grid>
      <LBTSpacer spacing={4} />
    </Section>
  );
}
